import React, { useEffect } from 'react';

const errorStates = {
  RESIZED_IMAGE_ERROR: 'RESIZED_IMAGE_ERROR',
  ORIGINAL_IMAGE_ERROR: 'ORIGINAL_IMAGE_ERROR'
};

export default function PreviewImage({
  src,
  remoteImagesPath,
  resizedRemoteImagesPath,
  resizedImageExtension,
  imgClass,
  onImageLoadError
}) {
  const [onError, setOnError] = React.useState();
  const imageExtension = '.png';
  const imagePath = `${remoteImagesPath}/${src}${imageExtension}`;
  const resizedImagePath = `${resizedRemoteImagesPath}/${src}.${
    resizedImageExtension || imageExtension
  }`;

  useEffect(() => {
    if (onError === errorStates.ORIGINAL_IMAGE_ERROR) {
      onImageLoadError();
    }
  }, [onError]);

  if (onError === errorStates.ORIGINAL_IMAGE_ERROR) {
    return null;
  } else if (onError === errorStates.RESIZED_IMAGE_ERROR) {
    return (
      <img
        src={imagePath}
        alt="Laydown image preview"
        className={imgClass}
        onError={() => setOnError(errorStates.ORIGINAL_IMAGE_ERROR)}
      />
    );
  } else {
    return (
      <img
        src={resizedImagePath}
        alt="Laydown image preview"
        className={imgClass}
        onError={() => setOnError(errorStates.RESIZED_IMAGE_ERROR)}
      />
    );
  }
}
